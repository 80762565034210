import Vue from 'vue'

const menu="position/";

export default {

    pagination(dtr){
        return Vue.prototype.$http.post(menu+"profileacademic",{},
    {params:{pstid:dtr.filter.PstID,grpid:1084}});},

    save(obj,usr){
       
        return Vue.prototype.$http.post(menu+"createProfile",obj,{params:{usrID:usr}});
    }
}