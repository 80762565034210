import Vue from 'vue'

const menu="position/";

export default {

    pagination(dtr){ return Vue.prototype.$http.post(menu+"positionreport",dtr);},

    save(obj,usr){
       
        return Vue.prototype.$http.post(menu+"createreport",obj,{params:{usrID:usr}});
    }
}