import Service from "../Service";

const resource = "position/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "genpositionobservationsave", cmp, {
            params: { requestID: requestID },
        });
    },

    list(filter, requestID) {
        return Service.post(resource + "genpositionobservationlist", filter, {
            params: { requestID: requestID },
        });
    },

    listrecord(filter, requestID) {
        return Service.post(resource + "genpositionobservationlistrecord", filter, {
            params: { requestID: requestID },
        });
    },

    listreportrecord(filter, requestID) {
        return Service.post(resource + "genpositionreportlistrecord", filter, {
            params: { requestID: requestID },
        });
    },

    listindicatorrecord(filter, requestID) {
        return Service.post(resource + "genpositionindicatorlistrecord", filter, {
            params: { requestID: requestID },
        });
    },

    listprofilerecord(filter, requestID) {
        return Service.post(resource + "genpositionprofilelistrecord", filter, {
            params: { requestID: requestID },
        });
    },
};