import Vue from 'vue'

const menu="position/";

export default {

    pagination(dtr){
        return Vue.prototype.$http.post(menu+"positionbackground",dtr);},

    save(obj,usr){
       
        return Vue.prototype.$http.post(menu+"createpositionbackground",obj,{params:{usrID:usr}});
    }
}