import Service from "../Service";

const resource = "position/";

export default {
  save(obj, requestID) {
    return Service.post(resource + "POSITIONINDICATOR_SAVE", obj, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "positionindicator_pagination", parameters, {
      params: { requestID: requestID },
    });
  },
};
  