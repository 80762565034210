import Vue from 'vue'

const menu="position/";

export default {

    pagination(dtr,pstid){return Vue.prototype.$http.post(menu+"positionfuctions",dtr,{params:{pstid:1}})},

    save(obj,usr){
       
        return Vue.prototype.$http.post(menu+"createfuction",obj,{params:{usrID:usr}});
    }
}