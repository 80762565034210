import Service from "../Service";

const resource = "genpositionrecord/";

export default {
    saveFunction(cmp, requestID) {
        return Service.post(resource + "savefunction", cmp, {
            params: { requestID: requestID },
        });
    },

    saveReport(cmp, requestID) {
        return Service.post(resource + "savereport", cmp, {
            params: { requestID: requestID },
        });
    },

    saveIndicator(cmp, requestID) {
        return Service.post(resource + "saveindicator", cmp, {
            params: { requestID: requestID },
        });
    },

    saveProfile(cmp, requestID) {
        return Service.post(resource + "saveprofile", cmp, {
            params: { requestID: requestID },
        });
    },
};